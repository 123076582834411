<template>
  <div id="home-view">
    <h1>Welcome</h1>
    <p>
      Primarily this page is here to serve as a resume supplement for
      prospective employers to see my portfolio. Welcome to you! Feel free to
      review my experience, education, and portfolio. This site itself is a
      frontend Vue.js application. You can see the code by clicking the
      <a href="http://github.com/" target="_blank">Github</a>
      icon in the footer. Each portfolio item has it's own github link so you
      needn't search.
    </p>

    <p>
      This page also serves to show off some of my hobbies. I am a fiction
      writer, a woodworker, a homeowner and that section will be constantly
      evolving.
    </p>

    <p>Thanks!</p>
    <p>Stephen</p>
  </div>
</template>
