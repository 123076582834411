<template>
  <div class="personal">
    <h1>Personal</h1>
    <p id="intro-paragraph">This page is where I'll place galleries and other snippets of my hobbies, home, family, 
      interests etc. Right now it's just the woodworking. There will be more to come but these 
      galleries were already ready already.</p>

    <h2>Woodworking</h2>
    <p>
      When we moved into our home in February of 2022, we had little furniture. So I bought some tools and got to work 
      learning to cut and glue. Here's the results!
    </p>
    

    <GalleryComponent :galleries="woodworkingItems" />
  </div>
</template>
s
<script>
import GalleryComponent from '@/components/GalleryComponent.vue';

export default {
  components: {
    GalleryComponent
  },
  data() {
    return {
      woodworkingItems: [
        {
          id: 1,
          beforeImage: '/assets/img/personal/woodwork/001workbench/before.jpg',
          afterImage: '/assets/img/personal/woodwork/001workbench/after.jpg',
          title: "Mobile Workbench",
          thumbs: [
            '/assets/img/personal/woodwork/001workbench/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/001workbench/thumbs/002t.jpg',
          ],
          fulls: [
            '/assets/img/personal/woodwork/001workbench/fulls/001.jpg',
            '/assets/img/personal/woodwork/001workbench/fulls/002.jpg',
          ],
          captions: [
            'The skeleton',
            'The mobile workbench',
          ],
        },
        {
          id: 2,
          beforeImage: '/assets/img/personal/woodwork/002endtables/before.jpg',
          afterImage: '/assets/img/personal/woodwork/002endtables/after.jpg',
          title: "End Tables",
          thumbs: [
            '/assets/img/personal/woodwork/002endtables/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/002endtables/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/002endtables/thumbs/003t.jpg',            
          ],
          fulls: [
            '/assets/img/personal/woodwork/002endtables/fulls/001.jpg',
            '/assets/img/personal/woodwork/002endtables/fulls/002.jpg',
            '/assets/img/personal/woodwork/002endtables/fulls/003.jpg',            
          ],
          captions: [
            'Scaffolding',
            'All built',
            'Painted and dry',
          ],
        },
        {
          id: 3,
          beforeImage: '/assets/img/personal/woodwork/003addressplates/before.jpg',
          afterImage: '/assets/img/personal/woodwork/003addressplates/after.jpg',
          title: "Address Plates",
          thumbs: [
            '/assets/img/personal/woodwork/003addressplates/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/003addressplates/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/003addressplates/thumbs/003t.jpg',            
          ],
          fulls: [
            '/assets/img/personal/woodwork/003addressplates/fulls/001.jpg',
            '/assets/img/personal/woodwork/003addressplates/fulls/002.jpg',
            '/assets/img/personal/woodwork/003addressplates/fulls/003.jpg',            
          ],
          captions: [
            'Circles are harder to figure out than I initially thought they would be',
            'Hangin',
            'Address and slat-bench!',
          ],
        },
        {
          id: 4,
          beforeImage: '/assets/img/personal/woodwork/004closetshelves/before.jpg',
          afterImage: '/assets/img/personal/woodwork/004closetshelves/after.jpg',
          title: "Closet Shelves",
          thumbs: [
            '/assets/img/personal/woodwork/004closetshelves/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/004closetshelves/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/004closetshelves/thumbs/003t.jpg',            
          ],
          fulls: [
            '/assets/img/personal/woodwork/004closetshelves/fulls/001.jpg',
            '/assets/img/personal/woodwork/004closetshelves/fulls/002.jpg',
            '/assets/img/personal/woodwork/004closetshelves/fulls/003.jpg',            
          ],
          captions: [
            'Parts',
            'Shelves for the shelves',
            'I shouldda finished them but now they\'re covered in clothes',
          ],
        },
        {
          id: 5,
          beforeImage: '/assets/img/personal/woodwork/005slatbench/before.jpg',
          afterImage: '/assets/img/personal/woodwork/005slatbench/after.jpg',
          title: "Slat Bench",
          thumbs: [
            '/assets/img/personal/woodwork/005slatbench/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/003t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/004t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/005t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/006t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/007t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/008t.jpg',
            '/assets/img/personal/woodwork/005slatbench/thumbs/009t.jpg',
          ],
          fulls: [
            '/assets/img/personal/woodwork/005slatbench/fulls/001.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/002.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/003.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/004.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/005.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/006.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/007.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/008.jpg',
            '/assets/img/personal/woodwork/005slatbench/fulls/009.jpg',         
          ],
          captions: [
            'Legs',
            'Gotta glue it in stages with all the slats',
            'Now we\'re getting there',
            'Glued up',
            'The trick is to put the whole thing on the tablesaw and keep it secret so people think you are just that precise.',
            'Edge',
            'On the enclosed porch',
            'Out front',
            'Address plates and slat bench.',
          ],
        },
        {
          id: 6,
          beforeImage: '/assets/img/personal/woodwork/006coffeetable/before.jpg',
          afterImage: '/assets/img/personal/woodwork/006coffeetable/after.jpg',
          title: "Coffee Table",
          thumbs: [
            '/assets/img/personal/woodwork/006coffeetable/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/003t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/004t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/005t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/006t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/007t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/008t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/009t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/010t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/011t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/012t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/013t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/014t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/015t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/016t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/017t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/018t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/019t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/020t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/021t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/022t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/023t.jpg',
            '/assets/img/personal/woodwork/006coffeetable/thumbs/024t.jpg',
          ],
          fulls: [
            '/assets/img/personal/woodwork/006coffeetable/fulls/001.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/002.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/003.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/004.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/005.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/006.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/007.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/008.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/009.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/010.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/011.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/012.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/013.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/014.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/015.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/016.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/017.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/018.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/019.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/020.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/021.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/022.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/023.jpg',
            '/assets/img/personal/woodwork/006coffeetable/fulls/024.jpg',            
          ],
          captions: [
            'Pieces',
            'Fun with strap clamps',
            'Corner imperfection',
            'Frames done',
            'Lamination: gluey',
            'Lamination: clean (they all got glued together and I had to redo it haha)',
            'Legs',
            'Everybody loves a jig',
            'Arrr. The ghost of jig mistakes!',
            'Gettin ready to stain',
            'Stainin\'',
            'Can I help you?',
            'Don\'t even say it, I already know',
            'Carbonite stain so saturated. So pretty. So permanent.',
            'More of the same',
            'Mmmm pretty',
            'Imperfect, but lovely',
            'I love the look of stain before you wipe it off',
            'Testing the size (and maple wood trays I made)',
            'Shiny top',
            'Assembly',
            'Mmmm, hovery.',
            'Living room',
            'Shiny', 
          ],
        },
        {
          id: 7,
          beforeImage: '/assets/img/personal/woodwork/007catstairs/before.jpg',
          afterImage: '/assets/img/personal/woodwork/007catstairs/after.jpg',
          title: "Cat Staircase",
          thumbs: [
            '/assets/img/personal/woodwork/007catstairs/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/007catstairs/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/007catstairs/thumbs/003t.jpg',
            '/assets/img/personal/woodwork/007catstairs/thumbs/004t.jpg',
          ],
          fulls: [
            '/assets/img/personal/woodwork/007catstairs/fulls/001.jpg',
            '/assets/img/personal/woodwork/007catstairs/fulls/002.jpg',
            '/assets/img/personal/woodwork/007catstairs/fulls/003.jpg',
            '/assets/img/personal/woodwork/007catstairs/fulls/004.jpg',        
          ],
          captions: [
            'Creating a striped plywood as an experiment',
            'The stripes after unclamping',
            'Right angle corner clamps are my guilty pleasure',
            'A grand escalier so his highness can sneeze upon my face as I sleep',
          ],
        },
        {
          id: 8,
          beforeImage: '/assets/img/personal/woodwork/008bespoketable/before.jpg',
          afterImage: '/assets/img/personal/woodwork/008bespoketable/after.jpg',
          title: "Bespoke Table",
          thumbs: [
            '/assets/img/personal/woodwork/008bespoketable/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/008bespoketable/thumbs/002t.jpg',
          ],
          fulls: [
            '/assets/img/personal/woodwork/008bespoketable/fulls/001.jpg',
            '/assets/img/personal/woodwork/008bespoketable/fulls/002.jpg',
          ],
          captions: [
            'Rolling Table plays dead',
            'The table I\'m probably sitting at right now.',
          ],
        },
        {
          id: 9,
          beforeImage: '/assets/img/personal/woodwork/050bedframe/before.jpg',
          afterImage: '/assets/img/personal/woodwork/050bedframe/after.jpg',
          title: "King-Size Bed Frame",
          thumbs: [
            '/assets/img/personal/woodwork/050bedframe/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/003t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/004t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/005t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/006t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/007t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/008t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/009t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/010t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/011t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/012t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/013t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/014t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/015t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/016t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/017t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/018t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/019t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/020t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/021t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/022t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/023t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/024t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/025t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/026t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/027t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/028t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/029t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/030t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/031t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/032t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/033t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/034t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/035t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/036t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/037t.jpg',
            '/assets/img/personal/woodwork/050bedframe/thumbs/038t.jpg',
          ],
          fulls: [
            '/assets/img/personal/woodwork/050bedframe/fulls/001.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/002.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/003.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/004.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/005.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/006.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/007.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/008.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/009.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/010.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/011.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/012.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/013.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/014.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/015.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/016.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/017.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/018.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/019.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/020.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/021.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/022.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/023.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/024.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/025.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/026.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/027.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/028.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/029.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/030.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/031.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/032.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/033.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/034.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/035.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/036.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/037.jpg',
            '/assets/img/personal/woodwork/050bedframe/fulls/038.jpg',
          ],
          captions: [
            'Long Media.',
            'Jig start for the bedpost mortises.',
            'Bedpost mortise jig complete',
            'A lot of this jig, I know. First use.',
            'First mortise complete.',
            'Second mortise.',
            'A perfect octagon from a 4x4 post.',
            'Several rough bedposts.',
            'Panels for headboard and footboard.',
            'Second jig. My perfect octagons.',
            'It fits!',
            'Cutting holes.',
            'Pure Danger! my 2"x1/4" bit barely made it. Never buy plastic bushings.',
            'All the short planks cut',
            'Moss-Maine Hockeypucks.',
            'A tough cut... but it fits so nice.',
            'Like a glove.',
            'The sanding begins.',
            'Taking over the fireplace room.',
            'Headboard/Footboard panels and the biggest side pieces.',
            'Pocket holes!',
            'Materials.',
            'Magic doggo.',
            'Magic bearcub.',
            'Preparing to stain.',
            'A stained bedpost with bare regions for wood glue.',
            'So many clamps.',
            'Magic emu.',
            'Magic stained doggo.',
            'Stain gallery. Waterbased color named "Carbonite."',
            'I called this piece "the cloud" during creation. It\'s blue like the sky and it floats.',
            'Footboard',
            'No slats... joists.',
            'Footboard Re-glue',
            'Celebration! I did it!',
            'The real cloud is the bedding.',
            'I miss Fang. He HATED being up there',
            'Look at that fine piece of woodwork.',
          ],
        },
        {
          id: 10,
          beforeImage: '/assets/img/personal/woodwork/500workshop/before.jpg',
          afterImage: '/assets/img/personal/woodwork/500workshop/after.jpg',
          title: "Workshop Structures",
          thumbs: [
            '/assets/img/personal/woodwork/500workshop/thumbs/001t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/002t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/003t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/004t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/005t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/006t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/007t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/008t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/009t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/010t.jpg',
            '/assets/img/personal/woodwork/500workshop/thumbs/011t.jpg',
          ],
          fulls: [
            '/assets/img/personal/woodwork/500workshop/fulls/001.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/002.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/003.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/004.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/005.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/006.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/007.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/008.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/009.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/010.jpg',
            '/assets/img/personal/woodwork/500workshop/fulls/011.jpg',
          ],
          captions: [
            'Shallow Shelf',
            'Drills and Impact Drivers',
            'With the sandpaper station',
            'Gluing up a mobile station for the chop saw',
            'I call this "Ingenuity or The Love Clamps"',
            'Wings at rest',
            'Wings in action',
            'Lumber Rack (and everything else)',
            'Clamp hanger glue-up',
            'Blue slots are for the thicc clamps',
            'It fits!',
          ],
        },
      ]
    }
  }
}
</script>

<style scoped>
#intro-paragraph {
  border-block-end: .25rem solid var(--color13);
}

h2, h3 {
  text-align: center;
}

</style>


